<!--  -->
<template>
  <div class="main has-header">
    <mt-header fixed title="常见问题">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="container">
      <!-- <h2>常见问题</h2> -->
      <h5>1、 为什么要实名认证？</h5>
      <p>
        您作为个体工商业者通过从事合法的生产经营活动获得收入，{{isXyk}}为您提供共享经济综合服务，其中包括为您结算、发放生产经营税后收入，并代征您个人所得税。
        {{isXyk}}在为您提供服务前，需要与您签订合作伙伴协议，使您在获得优质服务的同时，保障您的合法权益。
      </p>

      <h5>2、 实名认证后可以解绑吗？</h5>
      <p>目前不支持解绑，请如实填写个人信息。</p>

      <h5>3、 认证过程中提示银行卡与银行预留手机号不匹配？</h5>
      <p>请确认银行预留手机号是办理该银行卡时所填写的手机号码。如预留手机号忘记或停用，请联系银行客服处理。如您已确认预留号码无误，请联系（{{isXyk}}助手）在线客服处理</p>

      <h5>4、 怎么更换银行卡？</h5>
      <p>小程序端目前不支持更换银行卡，小程序中绑定银行卡作为实名认证的重要步骤，可以不作为收款账号。</p>
      <h5>5、 收不到验证码的原因？</h5>
      <p>（1） 手机装了拦截软件，请查看手机的软件设置。</p>
      <p>（2） 手机暂无信号、欠费或停机。</p>
      <p>（3） 用户自己在运营商那边设置了黑名单，可以联系运营商解决。</p>
      <p>（4） 个别地区有手机、运营商故障等。</p>
      <p>（5） 如还有其他问题，请联系（{{isXyk}}小助手）在线客服解决。</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isXyk: window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    }
  }
}
</script>


<style lang='less' scoped>
//@import url(); 引入公共css类
.container {
  padding: 15px 10px;
  margin-bottom: 50px;
}
.container h2 {
  color: #000;
  font-weight: normal;
}
.container h5{
  margin:8px 0;
}
.container p {
  background-color: #f9f8f8;
  color: #333;
  line-height: 30px;
  display: block;
  padding:5px;
  font-size:0.9rem;

}
</style>